import { GET, GetWithParam, POST, UPLOAD, DELETE, GET_WeChat } from "./request.js"

export function userLogin(data) {
  return POST("/user-login", data)
}

export function checkUser(data) {
  return POST("/check-user", data)
}

export function userRegister(data) {
  return POST("/user-register", data)
}
export function bindingPhone(data) {
  return POST("/binding-phone", data, true)
}
export function bindingEmail(data) {
  return POST("/binding-email", data, true)
}
export function sendChat(data) {
  return POST("/accept-chat", data, true)
}
export function updateUserAlias(data) {
  return POST("/update-user-alias", data, true)
}
export function updateAvatar(data) {
  return POST("/update-avatar", data, true)
}
export function wechatQrcode(data) {
  return GET_WeChat(`/wechat-qrcode?app_id=${data.app_id}`, data, true)
}
export function wechatQrcodeCallback(data) {
  return POST(`/wechat-auto-callback`, data, true)
}
export function getUserChatSession() {
  return GET("/user-chat-session", true)
}
export function payResult(data) {
  return GET(`/pay-result?no=${data.no}`, true)
}

export function getChatSessionDetail(sessionId, pageSize, pageNum) {
  return GET(
    `/chat-session-detail?sessionId=${sessionId}&pageSize=${pageSize}&pageNum=${pageNum}`,
    true
  )
}

export function updateChatAlias(data) {
  return POST("/update-chat-alias", data, true)
}

export function deleteUserSession(query) {
  return DELETE(`/delete-user-session/${query}/`)
}

export function getGptModels() {
  return GET("/gpt-models/")
}

export function sendEmail(data) {
  return POST("/send-email", data)
}
export function sendPhone(data) {
  return POST("/request-verify-code", data)
}
export function notifyPassword(data) {
  return POST("/notify-password", data)
}

export function queryTicket(data) {
  return POST("/query-ticket", data)
}

export function resetPassword(data) {
  return POST("/reset-password", data)
}

export function reGenerate(data) {
  return POST("/re-generate", data, true)
}

export function reChat(data) {
  return POST("/re-chat", data, true)
}

export function stopGenerate(data) {
  return POST("/stop-generate", data, true)
}

export function usageTracker(pageNum, pageSize) {
  return GET(`/usage-tracker?pageNum=${pageNum}&pageSize=${pageSize}`, true)
}
export function recordVisit() {
  return GET(`/record-visit`)
}
export function userInfo() {
  return GET(`/user-info`, true)
}
export function rechargeTracker(pageNum, pageSize) {
  return GET(`/recharge-tracker?pageNum=${pageNum}&pageSize=${pageSize}`, true)
}
export function submitOrder(data) {
  return POST(`/submit-order`, data, true)
}
export function submitFeedback(data) {
  return POST(`/submit-feedback`, data, true)
}
export function coursesExchange(data) {
  return POST(`/courses-exchange`, data, true, "rechargeDetail")
}
export function downLoadPPT() {
  return GET(`//https://qimiaoren.com/ppt/simple-creativity.pptx`, true)
}
export function updateBaseUsage(data) {
  return POST(`/update-base-usage`, data, true);
}
export function bindingOfficial(data) {
  return POST(`/binding-official`, data, true);
}
export function pptTemplates() {
  return GET(`/ppt-templates/`, true)
}
export function pptExport(params) {
  return GET(`/ppt-export?tmp_id=${params.tmp_id}&title=${params.title}`, true)
}
export function pptExportMoible(data) {
  return POST(`/ppt-export`, data, true)
}
export function doc() {
  return GET(`/doc/`, true)
}
export function pptPreview() {
  return GET(`/ppt-preview`, true)
}
