export default {
    namespaced: true,
    state: {
        excelList: [],
    },
    mutations: {
        setExcelList(state, excelList) {
            state.excelList = excelList
        }

    },
    actions: {
    }
}
