export default {
    namespaced: true,
    state: {
        title: '',
        asideList: []
    },
    mutations: {
        setTitle(state,title){
            state.title = title
        },
        setAsideList(state, payload) {
            state.asideList = payload
        }
    },
    actions: {
    }
}
