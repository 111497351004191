let containerType = localStorage.getItem('containerType') ? localStorage.getItem('containerType') : 1
export default {
    namespaced: true,
    state: {
        containerType: containerType
    },
    mutations: {
        setContainerType(state, payload) {
            // location.reload()
            state.containerType = payload
            localStorage.setItem('containerType', payload)
        }
    },
    actions: {
    }
}
