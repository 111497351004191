import Home from "@/views/Home/index.vue"
const UserInfo = () => import("@/views/payment/center/userInfo/index")
const UseForm = () => import("@/views/payment/center/useForm")
const PayForm = () => import("@/views/payment/center/payForm")
const AIEmployee = () => import("@/views/payment/center/aiEmployee")
const ThirdPartyPlatform = () => import("@/views/payment/center/thirdPartyPlatform")
const Recharge = () => import("@/views/recharge")
const RechargeDetail = () => import("@/views/recharge/rechargeDetail");
const RechargePay = () => import("@/views/recharge/pay");
const PaySuccess = () => import("@/views/recharge/paySuccess");
const PayError = () => import("@/views/recharge/payError");
const Center = () => import("@/views/payment/center")
const BindEmail = () => import("@/views/bind/bindEmail");
const BindPhone = () => import("@/views/bind/bindPhone");
export default [
  // 路由规则
  // {
  //     path: '/',
  //     name: '首页',
  //     component: () => import('@/views/Home.vue')
  // },
  // 6.28 当前版本暂不添加
  {
    path: '/ai-voice',
    name: 'aivoice',
    component: () => import("@/views/aivoice/index.vue")
  },
  {
    path: "/course",
    name: "课程",
    component: () => import("@/views/Course/index.vue"),
  },
  {
    path: "/PowerPoint",
    name: "PowerPoint",
    component: () => import("@/views/PowerPoint/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/welcome/welcome.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/register.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/views/reset/reset.vue"),
  },
  {
    path: "/resend",
    name: "resend",
    component: () => import("@/views/reset/resend.vue"),
  },
  {
    path: "/mobile",
    name: "mobile",
    component: () => import("@/components/flash/mobile.vue"),
  },
  {
    path: "/pc",
    name: "pc",
    component: () => import("@/components/flash/pc.vue"),
  },
  {
    path: "/qimiaoHuman",
    name: "qimiaoHuman",
    component: () => import("@/components/flash/qimiaoHuman.vue"),
  },
  // {
  //   path: "/welcomeBack",
  //   name: "welcomeBack",
  //   component: () => import("@/views/login/welcomeBack.vue"),
  // },
  // {
  //   path: "/welcome",
  //   name: "welcome",
  //   component: () => import("@/views/login/welcome.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("@/views/login/register.vue"),
  // },
  // {
  //   path: "/register-check",
  //   name: "register-check",
  //   component: () => import("@/views/login/register-check.vue"),
  // },
  {
    path: "/PowerPointTest",
    name: "PowerPointTest",
    component: () => import("@/views/PowerPoint/test.vue"),
  },
  {
    path: "/Recharge",
    name: "Recharge",
    component: Recharge,
  },
  {
    path: "/RechargeDetail",
    name: "RechargeDetail",
    component: RechargeDetail
  },
  {
    path: "/pay",
    name: "pay",
    component: RechargePay
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: PaySuccess
  },
  {
    path: "/payError",
    name: "payError",
    component: PayError
  },
  {
    path: "/center",
    name: "center",
    component: Center,
    children: [
      {
        path: "/useForm",
        name: "useForm",
        component: UseForm,
      },
      {
        path: "/userInfo",
        name: "userInfo",
        component: UserInfo,
      },
      {
        path: "/payForm",
        name: "payForm",
        component: PayForm,
      },
      {
        path: "/aiEmployee",
        name: "aiEmployee",
        component: AIEmployee,
      },
      {
        path: "/thirdPartyPlatform",
        name: "thirdPartyPlatform",
        component: ThirdPartyPlatform,
      },
    ],
  },
  {
    path: "/bindEmail",
    name: "bindEmail",
    component: BindEmail
  },
  {
    path: "/bindPhone",
    name: "bindPhone",
    component: BindPhone
  }
]
