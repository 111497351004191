export default {
    namespaced: true,
    state: {
        showToast: true,
        toastList: [],
        id: 0,
    },
    mutations: {
        setShowToast(state, show) {
            state.showToast = show
            state.toastList.push({
                id: state.id,
                msg: '123'
            })
            state.id++
            setTimeout(() => {
                state.showToast = false
            }, 3000)
        },
        closeToast(state) {
            state.showToast = false
        }
    },
    actions: {
    }
}
