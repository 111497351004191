export const themes = {
    default: {
        primaryColor: `rgba(${0}, ${168}, ${255},${1})`,
        primaryTextColor: `rgba(${255},${255},${255},${1})`,
        text: '默认主题',
        key: 'default',
    },
    pp: {
        primaryColor: `rgba(${61}, ${79}, ${178},${1})`,
        primaryTextColor: `rgba(${255},${255},${255},${1})`,
        text: '紫色主题',
        key: 'pp',
    },
    dark: {
        primaryColor: `rgba(${0},${0},${0},${1})`,
        primaryTextColor: `rgba(${255},${255},${255},${1})`,
        text: '暗黑主题',
        key: 'dark',
    },
}  