import fan_tabs from '@/data/tabs.js'
import router from '@/router'
export default {
    namespaced: true,
    state: {
        tabList: sessionStorage.getItem('tabs') ? JSON.parse(sessionStorage.getItem('tabs')) : fan_tabs,
        tabsType: localStorage.getItem("tabsType") ? localStorage.getItem("tabsType") : 1 // 1 - 多页签模式，2 - 单页签模式
    },
    mutations: {
        setTabsType(state, tabsType) {
            state.tabsType = tabsType
            localStorage.setItem('tabsType', tabsType)
        },
        setTabList(state, tabList) {
            state.tabList = tabList
        },
        removeTab(state, path) {
            // 删除tab
            let index = state.tabList.findIndex(item => item.path == path)
            state.tabList.splice(index, 1)
            // 更新tabs
            sessionStorage.setItem('tabs', JSON.stringify(state.tabList))
            // 如果当前不是最后一个，跳转至下一个tab
            if (index != state.tabList.length && router.currentRoute.path == path) {
                let nextPath = state.tabList[index] ? state.tabList[index].path : '/'
                router.push(nextPath)
            }
            // 如果当前是最后一个，跳转至上一个tab
            if (index == state.tabList.length) {
                let prevPath = state.tabList[index - 1] ? state.tabList[index - 1].path : '/'
                router.push(prevPath)
            }
        },
        // 删除全部tab
        removeAllTab(state) {
            state.tabList = [
                {
                    name: '首页',
                    path: '/',
                }
            ]
            console.log(state.tabList);
            sessionStorage.setItem('tabs', JSON.stringify(state.tabList))
            router.push('/')
        },
        // 删除其他tab
        removeOtherTab(state, path) {
            let index = state.tabList.findIndex(item => item.path == path)
            state.tabList = [...fan_tabs, state.tabList[index]]
            sessionStorage.setItem('tabs', JSON.stringify(state.tabList))
            router.push(path)
        },
        // 删除右侧tab
        removeRightTab(state, path) {
            let index = state.tabList.findIndex(item => item.path == path)
            state.tabList = state.tabList.slice(0, index + 1)
            sessionStorage.setItem('tabs', JSON.stringify(state.tabList))
            router.push(path)
        }
    },
    actions: {
    }
}
