import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'
import Index01 from '@/views/index01.vue'
import fan_routes from '@/data/routes.js'
import fan_tabs from '@/data/tabs.js'
import store from '@/store'
import { setTheme } from "@/theme/theme.js";
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },  
  {
    path: '/index01',
    name: 'index01',
    component: Index01
  },
]
routes.push(...fan_routes);
// console.log(fan_tabs);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let tabs = store.state.tabs.tabList;
  let tabsType = store.state.tabs.tabsType;
  sessionStorage.setItem('tabs', JSON.stringify(tabs))
  localStorage.setItem('tabsType', tabsType)
  // 多页签
  if (tabsType == 1) {
    if (from.path != '/login') {
      // to.path 是否在fan_tabs中
      let isExist = tabs.some(item => {
        return item.path == to.path
      })
      if (!isExist) {
        // 添加新的tab
        tabs.push({
          name: to.name,
          path: to.path
        })
        // 更新tabs
        sessionStorage.setItem('tabs', JSON.stringify(tabs))
      }
      store.commit('breadcrumb/setBreadList', to.matched)
    }
  } else if (tabsType == 2) {
    // 单页签
    if (from.path != '/login') {
      let isExist = tabs.some(item => {
        return item.path == to.path
      })
      let idx = tabs.findIndex(e => e.path == from.path)
      if (!isExist) {
        if (tabs.length == 1) {
          tabs.push({
            name: to.name,
            path: to.path
          })
        } else {
          tabs[1] = {
            name: to.name,
            path: to.path
          }
        }
        sessionStorage.setItem('tabs', JSON.stringify(tabs))
      }
      store.commit('breadcrumb/setBreadList', to.matched)
    }
  }
  // let tabsActive = document.getElementsByClassName("fan-tabs-item");
  store.commit('routes/setParentRoute', to.matched[0].path)
  setTheme();
  next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
