import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import theme from './theme'
import aside from './aside'
import routes from './routes'
import tabs from './Tabs'
import toast from './toast'
import breadcrumb from './breadcrumb'
import table from './table'

export default new Vuex.Store({
  state: {
    balance: null // 添加 balance 状态
  },
  mutations: {
    setBalance(state, newBalance) {
      state.balance = newBalance; // 更新 balance
    }
  },
  actions: {
    updateBalance({ commit }, newBalance) {
      commit('setBalance', newBalance); // 提交更新 balance 的 mutation
    }
  },
  modules: {
    theme,
    aside,
    routes,
    tabs,
    toast,
    breadcrumb,
    table
  }
})