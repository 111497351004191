<template>
  <div class="index flex-y-between-start" @click="click()">
    <el-container>
      <el-header>
        <div class="flex-x-between-centerHor">
          <div class="mouseHead level-two-title flex-x-left-centerHor" @click="$router.go(0)">
            <img src="../../public/logo.png" alt="" /> 奇妙人
          </div>
          <span class="wonderfulButton">
            <!-- <el-button type="danger" round  @click="toWelcome">登录 / 注册</el-button>
            <div class="toWelcome" @click="toWelcome">登录 / 注册</div> -->
            <el-link v-if="loginStatus" :underline="false" type="danger" class="toWelcome" @click="toWelcome()">
              登录 / 注册
            </el-link>
            <div class="userInfo" v-if="!loginStatus">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <div v-if="!loginStatus" class="header" @click="userinfoPop = !userinfoPop">
                    <img v-if="userAvatar" :src="userAvatar" class="userAvatar-01" alt="" />
                    <div v-if="!userAvatar" class="userAvatar">{{ username.slice(0, 2) }}</div>
                    <span>{{ username }}</span>
                  </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>{{ username }}</el-dropdown-item>
                  <!-- <el-dropdown-item>{{$t("用户档案")}}</el-dropdown-item> -->
                  <el-dropdown-item>Log out</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </span>
        </div>
      </el-header>
      <el-main>
        <div class="page flex-y-between-start">
          <div class="level-one-title">{{ $t("让每个人都用上人工智能") }}</div>
          <span class="wonderfulButton">
            <!-- <el-button type="danger" round @click="openKunono">开始体验</el-button> -->
            <!-- <el-button v-else type="danger" round @click="openKunono" style="margin-left: 0;">开始体验</el-button> -->
          </span>
        </div>
        <div class="page flex-y-between-start products">
          <div :class="$deviceClass ? 'flex-x-between level-one-title title' : 'flex-y-left level-one-title title'">
            <span class="tle">{{ $t("数字人") }}<br />
              <span class="text" style="margin-top: 30px;">
                数字人是一种基于人工智能技术的交互方式，通过语音识别、自然语言处理、对话管理和语音合成等技术，使人机之间能够进行自然、智能、流畅的语音交流。<br />
                <el-link type="danger">立即体验</el-link>
              </span>
            </span>
            <div class="mobile-view">
              <div class="mobile">
                <div class="screen">
                  <div class="talk" v-if="!ball">
                    <div class="flex-y-between-start">
                      <div class="flex-x-left-centerHor">
                        <div class="userAvatar flex-x-left-centerHor">
                          <i class="el-icon-user"></i>
                          <span class="problem" v-if="typedText">{{ typedText }}</span>
                        </div>
                      </div>
                      <div class="flex-x-left-centerHor">
                        <div class="userAvatar flex-x-left-centerHor">
                          <img src="../../public/logo1.png" alt="" />
                          <span class="problem" v-if="typedText01">{{ typedText01 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="icon" v-if="ball">
                    <div class="ball" v-if="ball">
                    </div>
                  </div>
                  <div class="ball" v-if="!ball"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div :class="$deviceClass ? 'flex-x-between views' : 'flex-y-between views'">
            <div class="mobileSty">
              <div class="mobile">
                <div class="screen">
                  <div class="talk" v-if="!ball">
                    <div class="flex-y-between-start">
                      <div class="flex-x-left-centerHor">
                        <div class="userAvatar flex-x-left-centerHor"><i class="el-icon-user"></i><span class="problem"
                            v-if="typedText">{{ typedText }}</span></div>
                      </div>
                      <div class="flex-x-left-centerHor">
                        <div class="userAvatar flex-x-left-centerHor"><img src="../../public/logo1.png" alt="" /><span class="problem"
                            v-if="typedText01">{{ typedText01 }}</span></div>
                      </div>
                    </div>

                  </div>
                  <div class="icon" v-if="ball">
                    <div class="ball" v-if="ball">
                    </div>
                  </div>
                  <div class="ball" v-if="!ball"></div>
                </div>
              </div>
            </div>
            <div class="PCSty">
              <div class="mobile">
                <div class="flex-x-left-centerHor btns">
                  <div class="red"></div>
                  <div class="yellow"></div>
                  <div class="green"></div>
                </div>                <div class="screen">
                  <div class="talk" v-if="!ball">

                    <div class="flex-y-between-start">
                      <div class="flex-x-left-centerHor">
                        <div class="userAvatar flex-x-left-centerHor"><i class="el-icon-user"></i><span class="problem"
                                                                                                        v-if="typedText">{{ typedText }}</span></div>
                      </div>
                      <div class="flex-x-left-centerHor">
                        <div class="userAvatar flex-x-left-centerHor"><img src="../../public/logo1.png" alt="" /><span class="problem"
                                                                                                        v-if="typedText01">{{ typedText01 }}</span></div>
                      </div>
                    </div>

                  </div>
                  <div class="icon" v-if="ball">
                    <div class="ball" v-if="ball">
                    </div>
                  </div>
                  <div class="ball" v-if="!ball"></div>
                </div>
              </div>

            </div>
          </div> -->
        </div>
      </el-main>
      <el-footer>
        <div :class="$deviceClass ? 'flex-x-between-evenly' : 'flex-y-between'">
          <span>@2023 上海果选网络科技有限公司</span>
          <span>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备2023017609号-1</a>
          </span>
          <span><a class="flex-x-between-evenly"
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020195" target="_blank"
              rel="noopener noreferrer"><img src="https://assets.mindshow.fun/assets/govbeian.png" alt=""
                style="height: 20px" />沪公安备案 31011502020195号</a></span>
          <!-- </span> -->
          <!-- <span v-if="!$deviceClass">备案地公安机关：上海市浦东新区网安支队</span> -->
          <!-- <span>联网备案时间：2023-09-20</span> -->
        </div>
        <!-- <div v-if="$deviceClass" class="flex-x-between-evenly"> -->
        <!-- <span>备案地公安机关：上海市浦东新区网安支队 </span> -->
        <!-- <span> 联网备案时间：2023-09-20</span> -->
        <!-- </div> -->
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { userInfo } from "../config/api.js"

export default {
  name: "index01",
  data() {
    return {
      username: "",
      userEmail: "",
      userPhone: "",
      userAvatar: "",
      userinfoPop: false, // 用户信息弹框
      ball: true,
      textProblem: "你认为ai对人类的生活、生产有什么影响",
      typedText: "",
      currentIndex: 0,
      textProblem01: "1.提高效率： 自动化和智能化的应用使得生产和服务过程更为高效，从而节省时间和资源。2.创新医疗： AI在医疗领域的应用使得疾病诊断更准确、药物研发更快捷，有望提高医疗水平并挽救更多生命。3.便捷生活： 智能家居、语音助手等技术使得日常生活更加方便，为人们提供更好的生活体验。个性化服务： AI能够根据个体需求提供个性化的服务，包括个性化推荐、教育资源等，提升用户体验。4.经济增长： AI促进了新的产业的兴起，创造了新的就业机会，推动了经济的增长。5.解决复杂问题： AI在数据分析、模拟等方面的能力使得人类能够更好地理解和解决复杂的问题，包括气候变化、环境保护等。",
      typedText01: "",
      currentIndex01: 0,
    }
  },
  created() {
    this.showBall()
  },
  mounted() {
    this.getuserInfo()
    // console.log(!window.localStorage.getItem("userData"))
  },
  methods: {
    toWelcome() {
      this.$router.push("/welcome")
    },
    openKunono() {
      window.open("https://www.kunono.com", "_blank")
    },
    toCourse() {
      this.$router.push("course")
    },
    toPPT() {
      let userData = localStorage.getItem("userData")
      let path = !userData ? "login" : "PowerPoint"
      if (path === "PowerPoint") {
        this.$router.push({ path })
      } else {
        this.$router.push({ path, query: { name: "PowerPoint" } })
      }
    },
    getuserInfo() {
      userInfo().then((res) => {
        this.dataLoad = false
        this.username = res.data.nickname || res.data.username
        this.userEmail = res.data.email
        this.userPhone = res.data.phone
        this.userAvatar = res.data.avatar
      })
    },
    click() {
      console.log(1)
      this.userinfoPop = false
    },
    showBall() {
      let secord = 3
      const intervalBall = setInterval(() => {
        secord--
        if (secord <= 0) {
          this.ball = false
          clearInterval(intervalBall)
          this.startTyping();
        }
      }, 1000)
    },
    startTyping() {
      const intervalId = setInterval(() => {
        this.typedText += this.textProblem[this.currentIndex];
        this.currentIndex++;

        if (this.currentIndex === this.textProblem.length) {
          clearInterval(intervalId);
          this.startTyping01()
        }
      }, 100);
    },
    startTyping01() {
      const intervalId01 = setInterval(() => {
        this.typedText01 += this.textProblem01[this.currentIndex01];
        this.currentIndex01++;

        if (this.currentIndex01 === this.textProblem01.length) {
          clearInterval(intervalId01);
        }
      }, 100);
    }
  },
  watch: {},
  computed: {
    loginStatus() {
      // 登录状态
      return !window.localStorage.getItem("userData")
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  // padding: 0 var(--box-interval);
  background-color: #000;

  .el-header {
    z-index: 3;

    .wonderfulButton {
      .toWelcome {
        font-weight: 700;
      }

      .header {
        cursor: pointer;
        display: flex;
        align-items: center;

        .userAvatar,
        .userAvatar-01 {
          width: 25px;
          height: 25px;
          line-height: 25px;
          margin-right: 10px;
          font-size: 12px;
          border-radius: 50%;
        }

        .userAvatar {
          background-color: #437eb4;
        }
      }
    }
  }

  .el-main {
    box-sizing: content-box;
    overflow: hidden;

    .page {
      border-bottom: 1px solid var(--header-font-color);
      padding-bottom: 60px;
      color: var(--header-font-color);

      >div {
        width: 100%;
      }

      .level-one-title {
        padding-top: 120px;
        font-weight: var(--pdl-font-weights-Normal);
      }

      .title {
        // padding-top: 60px;
        // padding-left: var(--box-interval);
      }

      .level-three-title {
        margin-top: var(--box-interval);
        font-size: 1.5rem;
        // div {
        //   display: inline-block;
        //   margin-top: 1rem;
        // }
      }

      .el-button {
        margin-top: var(--box-interval);
        // color: #d24726;
        // margin-left: var(--pdl-spacing-lg);
        color: var(--header-font-color);
        //   background-color: var(--theme-color);
        //   border: 1px solid var(--theme-color);
      }

      .el-button:hover,
      .el-button:focus {
        color: #333;
        background-color: var(--theme-color-20percent);
        border: 1px solid var(--theme-color-20percent);
      }

      .flex-x-between-evenly,
      .flex-x-between,
      .flex-y-between {
        margin-top: 1rem;

        .product {
          width: 23.5%;
          margin-bottom: 35px;

          img {
            width: 100%;
          }

          .details {
            margin-top: var(--pdl-spacing-lg);
            font-weight: var(--pdl-font-weights-bold);
            line-height: 1.5;
          }
        }
      }

      .flex-y-between {
        .product {
          width: 100%;
        }
      }

      >.wonderfulButton {
        height: 38px;
      }

      .tle {
        font-size: 3rem;
        flex: 1;
        text-align: left;
      }

      .text {
        font-size: 1.75rem;
        flex: 1;
        line-height: 1.5;
        margin-bottom: 1.5rem;
        text-align: left;
      }

      .mobile-view {
        flex: 1;

        .mobile {
          margin: auto;
          background-color: #fff;
          height: 500px;
          width: 250px;
          border-radius: 20px;
          padding: 10px 5px;
          box-shadow: var(--animationPhoneShadow);

          .screen {
            padding: 10px 10px;
            background-color: #000;
            height: 100%;
            width: 100%;
            border-radius: 20px;
            position: relative;

            .talk {

              // padding: .75rem;
              .flex-y-between-start {
                .flex-x-left-centerHor {
                  width: 100%;
                  margin-bottom: 5px;

                  .userAvatar {
                    align-items: flex-start;

                    i {
                      padding: 1px;
                      background-color: #437eb4;
                      margin-right: 10px;
                      font-size: 16px;
                    }

                    img {
                      padding: 1px;
                      width: 16px;
                      margin-right: 10px;
                      box-sizing: content-box;
                    }

                    .problem {
                      border-radius: 5px;
                      color: #000;
                      font-size: 12px;
                      text-align: left;
                      background: #d3d3d3;
                      padding: 3px;
                    }
                  }
                }
              }
            }

            .icon {
              height: 100%;
              position: relative;

              .ball {
                position: absolute;
                width: 50px;
                height: 50px;
                background-color: white;
                border-radius: 50%;
                animation: bounce 3.05s infinite alternate ease-in-out;
                left: 50%;
                bottom: 50%;
                transform: translate(-50%, 50%);
              }

              @keyframes bounce {
                0% {
                  width: 150px;
                  height: 150px;
                  opacity: 0.2;
                  transform: translate(-50%, 50%);
                }

                20% {
                  width: 140px;
                  height: 140px;
                  opacity: 0.8;
                  transform: translate(-50%, 50%);
                }

                40% {
                  width: 150px;
                  height: 150px;
                  opacity: 0.2;
                  transform: translate(-50%, 50%);
                }

                60% {
                  width: 140px;
                  height: 140px;
                  opacity: 0.8;
                  transform: translate(-50%, 50%);
                }

                80% {
                  width: 50px;
                  height: 50px;
                  opacity: 0.2;
                  bottom: 50%;
                  transform: translate(-50%, 50%);
                }

                99% {
                  opacity: 0.8;
                  bottom: 0%;
                  transform: translate(-50%, -50%);
                }

                100% {
                  opacity: 0.8;
                  bottom: 0%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .ball {
              position: absolute;
              width: 50px;
              height: 50px;
              background-color: white;
              border-radius: 50%;
              left: 50%;
              bottom: 0%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .views {
        flex: 1;

        .mobileSty,
        .PCSty {
          flex: 1;

          .mobile {
            margin: auto;
            background-color: #fff;
            height: 500px;
            width: 250px;
            border-radius: 20px;
            padding: 10px 5px;
            box-shadow: var(--animationPhoneShadow);

            .screen {
              padding: 10px 10px;
              background-color: #000;
              height: 100%;
              width: 100%;
              border-radius: 20px;
              position: relative;

              .talk {

                // padding: .75rem;
                .flex-y-between-start {
                  .flex-x-left-centerHor {
                    width: 100%;
                    margin-bottom: 5px;

                    .userAvatar {
                      align-items: flex-start;

                      i {
                        padding: 1px;
                        background-color: #437eb4;
                        margin-right: 10px;
                      }

                      img {
                        width: 16px;
                        margin-right: 10px;
                      }

                      .problem {
                        border-radius: 5px;
                        color: #000;
                        font-size: 12px;
                        text-align: left;
                        background: #d3d3d3;
                        padding: 3px;
                      }
                    }
                  }
                }
              }

              .icon {
                height: 100%;
                position: relative;

                .ball {
                  position: absolute;
                  width: 50px;
                  height: 50px;
                  background-color: white;
                  border-radius: 50%;
                  animation: bounce 3.05s infinite alternate ease-in-out;
                  left: 50%;
                  bottom: 50%;
                  transform: translate(-50%, 50%);
                }

                @keyframes bounce {
                  0% {
                    width: 150px;
                    height: 150px;
                    opacity: 0.2;
                    transform: translate(-50%, 50%);
                  }

                  20% {
                    width: 140px;
                    height: 140px;
                    opacity: 0.8;
                    transform: translate(-50%, 50%);
                  }

                  40% {
                    width: 150px;
                    height: 150px;
                    opacity: 0.2;
                    transform: translate(-50%, 50%);
                  }

                  60% {
                    width: 140px;
                    height: 140px;
                    opacity: 0.8;
                    transform: translate(-50%, 50%);
                  }

                  80% {
                    width: 50px;
                    height: 50px;
                    opacity: 0.2;
                    bottom: 50%;
                    transform: translate(-50%, 50%);
                  }

                  99% {
                    opacity: 0.8;
                    bottom: 0%;
                    transform: translate(-50%, -50%);
                  }

                  100% {
                    opacity: 0.8;
                    bottom: 0%;
                    transform: translate(-50%, -50%);
                  }
                }
              }

              .ball {
                position: absolute;
                width: 50px;
                height: 50px;
                background-color: white;
                border-radius: 50%;
                left: 50%;
                bottom: 0%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        .PCSty {
          flex: 3;

          .mobile {
            margin: auto;
            background-color: #fff;
            height: 500px;
            width: 760px;
            border-radius: 10px;
            padding: 15px 5px 5px;
            position: relative;

            .screen {
              border-radius: 10px;
            }

            .btns {

              .red,
              .yellow,
              .green {
                position: absolute;
                border-radius: 50%;
                background-color: #ee6a5f;
                width: 6px;
                height: 6px;
                top: 5px;
                left: 5px;
              }

              .yellow {
                left: 15px;
                background-color: #f6be4f;
              }

              .green {
                left: 25px;
                background-color: #62c554;
              }
            }
          }
        }
      }
    }

    .products {
      padding-bottom: 60px;
      background-color: var(--header-background-color);
      background-color: #400040;

      .title {
        padding-top: 0px !important;
      }
    }

    // .page:nth-child(odd) {
    //   background-color: var(--header-background-color);
    //   color: var(--header-font-color);
    // }

    // .page:nth-child(even) {
    //   background-color: var(--header-font-color);
    //   color: var(--header-background-color);
    // }
  }

  .el-footer {
    padding: 10px;
    height: 100% !important;

    span {
      height: 30px;
      line-height: 30px;
      font-size: var(--font-size-mini);
      color: var(--header-font-color);
      opacity: 0.5;
    }
  }

  .userInfo {
    color: #fff;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .index {
    .el-main {
      .el-header {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      .el-main {
        padding-right: 0 !important;
      }

      .page {
        .tle {
          font-size: 1.75rem;
          margin-top: .5rem;
        }

        .text {
          font-size: 1.2rem;
          margin-top: 1.5rem;
          line-height: 1.5;
        }
      }
    }
  }
}</style>
