export default {
    namespaced: true,
    state: {
        breadList: [],
    },
    mutations: {
        setBreadList(state, breadList) {
            let list = breadList.map(e => {
                return {
                    name: e.name,
                }
            })
            state.breadList = list
        }

    },
    actions: {
    }
}
