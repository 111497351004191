let functions = [
  {
    iconSvg: "ChatPageManageBotsGroup_searchIcon__S_sn_",
    iconPath:
      "M10.5 4a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM2 10.5a8.5 8.5 0 1 1 15.176 5.262l4.48 4.48a1 1 0 0 1-1.413 1.415l-4.481-4.481A8.5 8.5 0 0 1 2 10.5Z",
    described: "Explore",
  },
  {
    iconSvg:
      "ChatPageManageBotsGroup_createBotIcon_poeImproveBotDiscoverability__4ZhXw",
    iconPath:
      "M1.713 10.757H1.7v8.535c0 .51.415.92.923.92s.923-.41.923-.92v-8.238l.001-.043c.002-.042.005-.108.013-.194a7.133 7.133 0 0 1 .977-3.004c.95-1.572 2.89-3.273 7.073-3.273 4.047 0 5.918 1.59 6.833 3.091.961 1.576.968 3.222.965 3.388v2.074c0 .51.414.92.922.92.509 0 .923-.41.923-.92v-2.046c.005-.296-.004-2.357-1.233-4.371C18.745 4.585 16.252 2.7 11.61 2.7c-4.805 0-7.358 2.018-8.654 4.164a8.967 8.967 0 0 0-1.243 3.893Zm5.22 5.44a.918.918 0 0 1-.13-1.296.925.925 0 0 1 1.298-.129l.003.003.014.011.065.05a11.678 11.678 0 0 0 1.14.773c.759.45 1.559.788 2.14.788.582 0 1.382-.338 2.14-.787a11.65 11.65 0 0 0 1.205-.824l.014-.011.003-.002a.925.925 0 0 1 1.297.128.918.918 0 0 1-.128 1.295l-.001.001-.003.002-.006.005-.014.011-.01.009-.084.066a13.522 13.522 0 0 1-1.33.902c-.79.467-1.964 1.045-3.083 1.045-1.118 0-2.292-.578-3.082-1.045a13.517 13.517 0 0 1-1.414-.968l-.024-.02-.007-.005-.002-.002h-.001ZM9.119 9.96c.508 0 .922.41.922.92v1.57c0 .51-.414.92-.922.92a.921.921 0 0 1-.923-.92v-1.57c0-.51.414-.92.923-.92Zm4.805 0c.508 0 .923.41.923.92v1.57c0 .51-.415.92-.923.92a.921.921 0 0 1-.923-.92v-1.57c0-.51.415-.92.923-.92ZM20.33 15.3c.508 0 .922.41.922.92v1.116h1.124c.508 0 .923.41.923.92s-.415.92-.923.92h-1.124v1.205c0 .51-.414.92-.922.92a.921.921 0 0 1-.923-.92v-1.205h-1.213a.921.921 0 0 1-.923-.92c0-.51.415-.92.923-.92h1.213V16.22c0-.51.414-.92.922-.92Z",
    described: "Create bot",
  },
]
export default functions
