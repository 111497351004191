import axios from 'axios'
import store from '../store'

// const BASE_IP = 'http://192.168.5.5:8000/dj'
// const WELCOME_BASE = 'http://192.168.5.2:8081/' // 开发环境
// const WS_ADDR = 'ws://127.0.0.1:8000'

// const WELCOME_BASE = 'http://47.96.252.147/' // 测试服link
// const WS_ADDR = 'ws://47.96.252.147' // 测试服webScoket

const WELCOME_BASE = 'https://www.qimiaoren.com/' // 正式服link
const WS_ADDR = 'wss://www.qimiaoren.com' // 正式服webScoket

const BASE_IP = WELCOME_BASE + 'dj'
const BASE_IP_WeChat = WELCOME_BASE + 'wechat' 

const BASE_URL = `${BASE_IP}`
const BASE_URL_WeChat = `${BASE_IP_WeChat}`

export function GET (url, requireAuth=false) {

  let axiosRequestConfig = {}
  let headers = {}

  if (requireAuth) {
    // console.log('userData',localStorage.getItem("userData"))
    // if (localStorage.getItem("userData")) {      
    headers['Authorization'] = JSON.parse(localStorage.getItem("userData"))["token"]
    // } else {
    //   alert('您的登录信息已过期，请重新登录！')
    //   window.location.href = '/login'; // 跳转到首页
    //   location.href = WELCOME_BASE       
    // }
  }
  axiosRequestConfig['headers'] = headers
  return new Promise((resolve, reject) => {
    axios.get(BASE_URL + url,axiosRequestConfig)
      .then(res => { resolve(res) })
      .catch(err => {
        console.log(err)
        if (err.response.status == 403){
          localStorage.removeItem("userData");
          // location.href = "/login"
          location.href = "/" 
        }
      })
  })
}
export function GET_WeChat (url, requireAuth=false) {
console.log(BASE_URL_WeChat)
  let axiosRequestConfig = {}
  let headers = {}

  if (requireAuth) {
    // console.log('userData',localStorage.getItem("userData"))
    // if (localStorage.getItem("userData")) {      
    headers['Authorization'] = JSON.parse(localStorage.getItem("userData"))["token"]
    // } else {
    //   alert('您的登录信息已过期，请重新登录！')
    //   window.location.href = '/login'; // 跳转到首页
    //   location.href = WELCOME_BASE       
    // }
  }
  axiosRequestConfig['headers'] = headers
  return new Promise((resolve, reject) => {
    axios.get(BASE_URL_WeChat + url,axiosRequestConfig)
      .then(res => { resolve(res) })
      .catch(err => {
        console.log(err)
        if (err.response.status == 403){
          localStorage.removeItem("userData");
          location.href = "/login"
          // location.href = WELCOME_BASE 
        }
      })
  })
}

export function GetWithParam (url, params) {
  return new Promise((resolve,reject) => {
    axios({
      method: 'get',
      url: BASE_URL + url,
      params
    })
    .then(res => { resolve(res) })
    .catch(err => {
      console.log(err)
      if (err.response.status == 403){
        localStorage.removeItem("userData");
        location.href = "/login"
      }
    })
  })
}

export function POST (url, data, requireAuth=false,linkPath) {

  let axiosRequestConfig = {
    method: 'post',
    url: BASE_URL + url,
    data
  }
  return new Promise((resolve,reject) => {

    if (requireAuth) {
      axiosRequestConfig['headers'] = {
        'Authorization': JSON.parse(localStorage.getItem("userData"))["token"]
      }
    }
    axios(axiosRequestConfig).then(res => { resolve(res) })
    .catch(err => {
      console.log(err)
      if (err.response === "") alert(err.message);  // 1.0.4 掉线情况处理
      if (linkPath) {  // 1.0.4 需要跳转链接的情况
        const url = `/${linkPath}?status=${err.response.status}`;
        window.location.href = url
      } else {
        alert(err.message);
      }
      if (err.response.status == 403){
        localStorage.removeItem("userData");
        location.href = "/login"
      }
    })
  })
}

export function DELETE (url) {
  let axiosRequestConfig = {
    method: 'delete',
    url: BASE_URL + url,
    headers: {
      "Authorization": JSON.parse(localStorage.getItem("userData"))["token"]
    }
  }
  return new Promise((resolve,reject) => {
    axios(axiosRequestConfig).then(res => { resolve(res) })
    .catch(err => {
      console.log(err)
      if (err.response.status == 403){
        localStorage.removeItem("userData");
        location.href = "/login"
      }
    })
  })
}

export function getWsAddr () {
  return WS_ADDR
}
export function getBaseIp () {
  return BASE_IP
}
