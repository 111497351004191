<template>
  <div class="index">
    <header class="header">
      <div class="flex-x-between-centerHor">
        <div
          class="mouseHead level-two-title flex-x-left-centerHor"
          @click="$router.go(0)"
        >
          <img src="../../public/logo.png" alt="" />
          <span style="width: 90px">奇妙人</span>
        </div>
        <span class="wonderfulButton">
          <!-- <el-button type="danger" round  @click="toWelcome">登录 / 注册</el-button>
            <div class="toWelcome" @click="toWelcome">登录 / 注册</div> -->
          <el-link
            v-if="loginStatus"
            :underline="false"
            class="toRegister"
            @click="toRegister()"
          >
            注册
          </el-link>
        </span>
      </div>
    </header>
    <div class="content">
      <div class="slogan">让每个人都用上人工智能</div>
      <el-button class="login" @click="toLogin">登录</el-button>
    </div>

    <div class="content content-01">
      <div
        :class="
          $deviceClass
            ? 'flex-x-between-evenly show-view'
            : 'flex-y-between show-view'
        "
      >
        <section>
          <div class="phone-view flex-y-between">
            <div class="view">
              <mobile></mobile>
            </div>
          </div>
        </section>
        <section class="text">
          <div class="text-view">
            <h1>AI员工（奇妙人）</h1>
            <p>
              基于人工智能技术，通过语音识别、自然语言处理、对话管理和语音合成等技术，像一位聪明而高效的员工，不知疲倦地完成线下线上各类工作，接待，咨询，讲解，客服，导购，销售，策划，财务，数据分析。
              <el-link :underline="false" type="danger" @click="toQimiaoren">立即体验</el-link>
            </p>
          </div>
        </section>
      </div>
      <!-- ai生成PPT -->
      <!-- <div :class="$deviceClass ? 'flex-x-between-evenly show-view' : 'flex-y-between show-view'">
        <section>
          <div class="phone-view flex-y-between">
            <img src="../assets/images/products/5.png" alt="" srcset="">
          </div>
        </section>
        <section class="text">
          <div class="text-view">
            <h1>AI生成PPT</h1>
            <p>保存您的支付信息，将来您在启用了 Link 的所有网站上购物时，我们将自动填写您保存的银行卡详情、地址和电话号码。点击确认订单之前，您可以先检查订单。</p>
          </div>
        </section>
      </div> -->
      <!-- ai课程 -->
      <!-- <div v-if="$deviceClass" class="flex-x-between-evenly show-view">
        <section class="text">
          <div class="text-view">
            <h1>AI课程</h1>
            <p>保存您的支付信息，将来您在启用了 Link 的所有网站上购物时，我们将自动填写您保存的银行卡详情、地址和电话号码。点击确认订单之前，您可以先检查订单。</p>
          </div>
        </section>
        <section>
          <div class="phone-view flex-y-between">
            <img src="../assets/images/products/5.png" alt="" srcset="">
          </div>
        </section>
      </div>
      <div v-if="!$deviceClass" class="flex-y-between show-view">
        <section>
          <div class="phone-view flex-y-between">
            <img src="../assets/images/products/5.png" alt="" srcset="">
          </div>
        </section>
        <section class="text">
          <div class="text-view">
            <h1>AI课程</h1>
            <p>保存您的支付信息，将来您在启用了 Link 的所有网站上购物时，我们将自动填写您保存的银行卡详情、地址和电话号码。点击确认订单之前，您可以先检查订单。</p>
          </div>
        </section>
      </div> -->
    </div>
    <div class="content content-01 content-02">
      <h1>AI员工可用在哪些场景</h1>
      <!-- <h2>使用 Link，在这些网站及成千上万其他网站上轻松安全地结账。</h2> -->
      <div
        :class="$deviceClass ? 'flex-x-between imgs' : 'flex-y-between imgs'"
      >
        <div
          class="division hoverHead"
          @mouseover="iconFontColor01 = '#2dc49f'"
          @mouseleave="iconFontColor01 = '#fff'"
        >
          <img src="../assets/images/products/1.png" alt="" />
          <div class="flex-x-left-centerHor">
            <i
              class="iconfont icon-youshangfang"
              :style="'background-color:' + iconFontColor01"
            ></i
            ><span>可用于公司前台接待</span>
          </div>
        </div>
        <div
          class="division hoverHead"
          @mouseover="iconFontColor02 = '#2dc49f'"
          @mouseleave="iconFontColor02 = '#fff'"
        >
          <img src="../assets/images/products/2.png" alt="" />
          <div class="flex-x-left-centerHor">
            <i
              class="iconfont icon-youshangfang"
              :style="'background-color:' + iconFontColor02"
            ></i
            ><span>可用于商场咨询导购</span>
          </div>
        </div>
        <div
          class="division hoverHead"
          @mouseover="iconFontColor03 = '#2dc49f'"
          @mouseleave="iconFontColor03 = '#fff'"
        >
          <img src="../assets/images/products/3.png" alt="" />
          <div class="flex-x-left-centerHor">
            <i
              class="iconfont icon-youshangfang"
              :style="'background-color:' + iconFontColor03"
            ></i
            ><span>可用于线上销售咨询</span>
          </div>
        </div>
        <div
          class="division hoverHead"
          @mouseover="iconFontColor04 = '#2dc49f'"
          @mouseleave="iconFontColor04 = '#fff'"
        >
          <img src="../assets/images/products/4.png" alt="" />
          <div class="flex-x-left-centerHor">
            <i
              class="iconfont icon-youshangfang"
              :style="'background-color:' + iconFontColor04"
            ></i
            ><span>可用于企业办公助手</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content content-01 pay">
      <!-- 企业chat -->
      <div v-if="$deviceClass" class="flex-x-between-centerHor show-view">
        <section class="text">
          <div class="text-view">
            <h1>企业ChatGPT</h1>
            <p>
              专属于企业内部以及关联伙伴使用，打破服务地区的限制，账号问题，网络问题等各种受限制问题，为企业量身定制稳定高效，数据安全，隐私保护，员工AI使用培训一站式解决方案。
            <el-link :underline="false" type="danger" @click="toKunono">立即体验</el-link>
            </p>
          </div>
        </section>
        <section>
          <div class="phone-view flex-y-between PCSty">
            <div class="view">
              <pc></pc>
            </div>
            <!-- <img class="view" src="../assets/images/products/6.png" alt="" srcset=""> -->
          </div>
        </section>
      </div>
      <div v-if="!$deviceClass" class="flex-y-between show-view">
        <section>
          <div class="phone-view flex-y-between PCSty">
            <!-- <img class="view" src="../assets/images/products/6.png" alt="" srcset=""> -->
            <div class="view">
              <pc></pc>
            </div>
          </div>
        </section>
        <section class="text">
          <div class="text-view">
            <h1>企业ChatGPT</h1>
            <p>
              专属于企业内部以及关联伙伴使用，打破服务地区的限制，账号问题，网络问题等各种受限制问题，为企业量身定制稳定高效，数据安全，隐私保护，员工AI使用培训一站式解决方案。
            <el-link :underline="false" type="danger" @click="toKunono">立即体验</el-link>
            </p>
          </div>
        </section>
      </div>
    </div>
    <div class="content content-01 content-02">
      <h1>企业chatGPT的优势</h1>
      <div :class="$deviceClass ? 'flex-x-between' : 'flex-y-between'">
        <div class="protect-list">
          <!-- <i class="el-icon-lock"></i> -->
          <img src="../assets/images/products/safe.png" alt="" />
          <div class="protect-list-text">
            <p class="text-01">安全稳定</p>
            <p class="text-02">
              专线使用，不再受到地区限制服务，账号问题，网络连接等各种问题
            </p>
          </div>
        </div>
        <div class="protect-list">
          <!-- <i class="el-icon-mobile-phone"></i> -->
          <img src="../assets/images/products/lock.png" alt="" />
          <div class="protect-list-text">
            <p class="text-01">隐私保护</p>
            <p class="text-02">
              数据不会用于训练，使用数据归属企业自身并加密存储
            </p>
          </div>
        </div>
        <div class="protect-list">
          <!-- <i class="iconfont icon-anquan"></i> -->
          <img src="../assets/images/products/mobile.png" alt="" />
          <div class="protect-list-text">
            <p class="text-01">员工培训</p>
            <p class="text-02">
              专家AI使用培训，由浅入深充分助力企业利用人工智能
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content content-01 problems pay">
      <h1 class="">常见问题</h1>
      <div class="protect-list-text problem">
        <p class="text-01">AI员工线下线上都可以使用吗？</p>
        <p class="text-02">
          都可以，线下可以直接面对面服务对话的人以及一些操作，线上可以嵌入网站，公众号，客服系统，办公系统等。
        </p>
      </div>
      <div class="protect-list-text problem">
        <p class="text-01">AI员工可以定制吗？</p>
        <p class="text-02">
          可以，可以针对特定场景定制，可以定制单个员工也可以批量定制。
        </p>
      </div>
      <div class="protect-list-text problem">
        <p class="text-01">企业ChatGPT在手机电脑都能使用吗？</p>
        <p class="text-02">
          都可以，电脑平板手机都可以使用，而且使用记录在云端可以无缝衔接。
        </p>
      </div>
      <div class="protect-list-text problem"></div>
    </div>
    <footer>
      <div class="card">
        <h1>开始使用奇妙人</h1>
        <el-button class="register" @click="toRegister">注册</el-button>
        <h2>有意在您的公司使用企业ChatGPT？</h2>
        <h3 class="mouseHead" @click="toKunono">企业ChatGPT</h3>
        <div class="foot flex-x-between">
          <span class="logo flex-x-left-centerHor" style="width: 100px;"
            ><img src="../../public/logofoot.png" alt="" /> 奇妙人</span
          >
          <span class="copy">
            <div
              :class="$deviceClass ? 'flex-x-between-evenly' : 'flex-y-left'"
            >
              <span>copyright @果选科技 </span>
              <span
                ><a
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  沪ICP备2023017609号-1
                </a></span
              >
              <span style="" class="other"
                ><a
                  class="flex-x-between-evenly"
                  href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020195"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img
                    src="https://assets.mindshow.fun/assets/govbeian.png"
                    alt=""
                  />沪公网安备 31011502020195号</a
                ></span
              >
            </div>
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import mobile from "@/components/flash/mobile.vue"
import pc from "@/components/flash/pc.vue"

export default {
  name: "index01",
  components: { mobile, pc },
  data() {
    return {
      loginStatus: true,
      ball: true,
      textProblem: "你认为ai对人类的生活、生产有什么影响",
      typedText: "",
      currentIndex: 0,
      textProblem01:
        "1.提高效率： 自动化和智能化的应用使得生产和服务过程更为高效，从而节省时间和资源。2.创新医疗： AI在医疗领域的应用使得疾病诊断更准确、药物研发更快捷，有望提高医疗水平并挽救更多生命。3.便捷生活： 智能家居、语音助手等技术使得日常生活更加方便，为人们提供更好的生活体验。个性化服务： AI能够根据个体需求提供个性化的服务，包括个性化推荐、教育资源等，提升用户体验。4.经济增长： AI促进了新的产业的兴起，创造了新的就业机会，推动了经济的增长。5.解决复杂问题： AI在数据分析、模拟等方面的能力使得人类能够更好地理解和解决复杂的问题，包括气候变化、环境保护等。",
      typedText01: "",
      currentIndex01: 0,
      iconFontColor01: "#fff",
      iconFontColor02: "#fff",
      iconFontColor03: "#fff",
      iconFontColor04: "#fff",
    }
  },
  created() {
    this.showBall()
  },
  methods: {
    showBall() {
      let secord = 3
      const intervalBall = setInterval(() => {
        secord--
        if (secord <= 0) {
          this.ball = false
          clearInterval(intervalBall)
          this.startTyping()
        }
      }, 1000)
    },
    startTyping() {
      const intervalId = setInterval(() => {
        this.typedText += this.textProblem[this.currentIndex]
        this.currentIndex++

        if (this.currentIndex === this.textProblem.length) {
          clearInterval(intervalId)
          this.startTyping01()
        }
      }, 100)
    },
    startTyping01() {
      const intervalId01 = setInterval(() => {
        this.typedText01 += this.textProblem01[this.currentIndex01]
        this.currentIndex01++

        if (this.currentIndex01 === this.textProblem01.length) {
          clearInterval(intervalId01)
        }
      }, 100)
    },
    toQimiaoren(){
      location.href = "/ai-voice"
    },
    toRegister() {
      this.$router.push("/register")
    },
    toLogin() {
      this.$router.push("/login")
    },
    toKunono() {
      window.open("https://www.kunono.com/", "_blank")
    },
  },
}
</script>
<style scoped lang="less">
.index {
  background: radial-gradient(
    241.49% 92.92% at 85.26% 77.98%,
    #fff 63%,
    #b2ffe3 81.25%,
    #feffbd 95.64%
  );
  background-repeat: no-repeat;
  background-size: 100% 4000px;
  color: #1d3944;
  padding-bottom: var(--footer--padding);

  .header,
  .content {
    width: 100%;
    max-width: 1144px;
    margin: 0 auto;
    padding: 32px 0;
    box-sizing: content-box;
    height: 40px;
    line-height: 40px;

    .flex-x-between-centerHor {
      font-size: 15px;
      color: #1d3944;
      margin-left: var(--layoutGutter);

      .flex-x-left-centerHor {
        font-size: 24px;
        font-weight: var(--pdl-font-weights-bold);

        img {
          padding: 1px;
          width: auto;
          height: 24px;
          margin-right: 10px;
          box-sizing: content-box;
        }
      }
      .flex-x-left-centerHor:hover {
        opacity: 0.8;
      }

      .wonderfulButton > .el-link.el-link--default {
        color: #1d3944 !important;
      }

      .wonderfulButton > .el-link.el-link--default:hover {
        opacity: 0.8;
      }

      .toRegister {
        font-size: 15px;
        font-weight: 700;
        padding: 14px 16px 11px;
      }
    }
  }

  .header {
    padding-right: 0;
    line-height: 1;
  }

  .content {
    max-width: calc(
      var(--layoutMaxColumnWidth) * var(--layoutMaxColumnCount) +
        var(--layoutColumnGap) * (var(--layoutMaxColumnCount) - 1) +
        var(--layoutGutter) * 2
    );
    height: 100%;
    padding: var(--sectionRowPaddingTop) var(--layoutGutter)
      var(--sectionRowPaddingBottom);
    box-sizing: border-box;

    .slogan {
      padding-right: 1.5em;
      width: 100%;
      max-width: 700px;
      font-size: var(--sloganFont);
      font-weight: var(--fontWeightNormal);
      line-height: 1.3;
      color: #1d3944;
    }

    .login {
      background-color: #1d3944;
      border: 0;
      color: #e3fff2;
      font-weight: var(--pdl-font-weights-bold);
      padding: 14px 31.75px 11px;
      font-size: 15px;
      margin-top: var(--login-top);
      border-radius: 8px;
    }

    .login:hover {
      opacity: 0.8;
    }
    .show-view {
      margin-top: 0 !important;
      // display: grid;
      // gap: 48px 24px;
      display: flex;
      grid: auto/repeat(2, 1fr);
      align-items: center;

      > section {
        width: 100%;
        max-width: 700px;

        .phone-view {
          max-width: 338px;
          height: 692px;
          line-height: 692px;
          margin: 0 auto;
          background-color: #fff;
          border-radius: 48px;
          box-shadow: var(--animationPhoneShadow);
          transform: scale(var(--animationScale));
          transform-origin: top;

          .view {
            width: 300px;
            height: 655px;
            border-radius: 48px;
            background: #000;
            position: relative;
          }
        }
        .PCSty {
          max-width: var(--PCSty-width);
          height: calc(var(--PCSty-width) / 17 * 24);
          // max-width: 692px / 4 * 3;
          // height: 692px;
          width: 100%;
          border-radius: 18px;
          background-color: #000;
          // background: linear-gradient(to bottom, #fff, #ffffff);
          .view {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            background-color: #fff;
            border-radius: 0;
            line-height: 1.75;
          }
        }

        .text-view {
          align-content: flex-start;
          color: #1d3944;

          h1 {
            padding-top: 13px;
            font-size: var(--content-01-font-size);
            margin-bottom: var(--content-01-bottom);
          }

          p {
            line-height: 25.5px;
            padding-bottom: 15px;
            padding-right: var(--content-01-p);
            width: 100%;
          }
        }
      }

      .text {
        margin-left: var(--home-text-left);
        margin-top: var(--home-text-top);
        margin-bottom: var(--home-text-bottom);
      }
    }

    .protect-list {
      width: 100%;
      max-width: var(--protect-list-maxWidth);
      padding-bottom: var(--protect-list-bottom);

      i {
        font-size: 37px;
        font-weight: var(--pdl-font-weights-bold);
      }

      img {
        width: 35px;
        height: 42px;
      }

      .protect-list-text {
        margin-top: 10px;

        .text-01 {
          border-top: var(--border-top);
          font-weight: var(--pdl-font-weights-bold);
          font-size: var(--protect-list-text-01);
          padding-top: 10px;
        }

        .text-02 {
          // width: var(--protect-list-text-02Width);
          font-size: 14px;
          line-height: 1.75;
          margin-top: 9px;
          padding-right: 2em;
        }
      }
    }
  }

  .content-01 {
    padding: var(--content-01-top) var(--content-01-left);

    h1 {
      font-size: var(--content-01-font-size);
    }

    .imgs {
      margin-top: 65px;

      .division {
        margin-bottom: var(--division);

        img {
          cursor: pointer;
          border-radius: var(--division-radius);
          width: var(--division-img);
        }

        .flex-x-left-centerHor {
          font-size: var(--division-text);

          i {
            height: 34px;
            line-height: 34px;
            font-size: 34px;
            margin-right: 12px;
            border-radius: 50%;
            margin-top: -2px;
          }
        }
      }
    }

    .flex-x-between {
      margin-top: var(--content-01-h1-bottom);
    }

    .flex-y-between {
      margin-top: 30px;
    }
  }
  .pay{
    padding-top: var(--pay-top); > .show-view {
    margin-left: 0;
    > .text {
      margin-top: 0;
      max-width: var(--pay-text);
      margin-left: 0;
    }
  }}

  .content-02 {
    padding-top: var(--protect);
    margin-top: var(--protect-margin);
    padding-bottom: var(--protect-bottom);
  }

  .problems {
    padding: var(--content-01-top) var(--content-01-left)
      var(--content-01-problemsBottom);

    h1 {
      line-height: 1.3 ;
      padding-bottom: var(--problems-h1bottom);
    }

    h2 {
      margin-top: 15px;
    }

    .problem {
      padding: 40px 0;
      border-top: var(--border-top);

      .text-01 {
        font-weight: var(--pdl-font-weights-bold);
        font-size: var(--problem-text01);
        line-height: 1.4;
      }

      .text-02 {
        padding-top: 15px;
        width: 100%;
        max-width: 864px;
        line-height: 1.6;
      }
    }

    .flex-x-between {
      margin-top: var(--content-01-h1-bottom);
    }
  }
  .pay{
    padding-top: var(--pay-top); > .show-view {
    margin-left: 0;
    > .text {
      margin-top: 0;
      max-width: var(--pay-text);
      margin-left: 0;
    }
  }}
  footer {
    width: calc(100% - var(--footer--padding) * 2);
    margin: var(--footer--padding) var(--footer--padding) 0
      var(--footer--padding);
    background-color: #1d3944;
    border-radius: 8px;

    .card {
      color: #fff;
      padding: var(--footer--card--padding);
      box-sizing: content-box;
      border-radius: var(--footer--border);
      background-color: #1d3944;
      max-width: 1080px;
      margin: auto;

      .register {
        border: 0;
        background-color: #33ddb3;
        color: #1d3944;
        font-size: 15px;
        font-weight: var(--pdl-font-weights-bold);
        margin-top: var(--footer--card--registerTop);
        padding: 15px 32px 10px;
        border-radius: 8px;
      }

      h1 {
        width: 100%;
        max-width: 896px;
        font-size: var(--footer--card--h1Font);
      }

      h2 {
        width: 100%;
        max-width: 896px;
        font-size: var(--protect-list-text-01);
        font-weight: var(--pdl-font-weights-bold);
        margin-top: var(--footer--card--h2top);
      }

      h3 {
        font-size: 14px;
        margin-top: 25px;
        color: #33ddb3;
        font-weight: var(--pdl-font-weights-bold);
      }

      .foot {
        margin-top: var(--footer--card--fooTop);
        border-top: 1px solid #61757c;
        padding-top: 15px;
        width: 100%;

        .logo,
        .other {
          font-weight: var(--pdl-font-weights-bold);
          font-size: 22px;

          > img {
            margin-right: 10px;
            height: 24px;
          }
        }

        .logo:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        .copy {
          font-size: 14px;
          height: var(--copy-height);
          line-height: var(--copy-height);
        }

        .flex-x-between-evenly {
          .other {
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            font-weight: var(--pdl-font-weights-bold);

            img {
              margin-right: 5px;
            }
          }

          span {
            font-weight: var(--pdl-font-weights-bold);
            margin-left: 16px;
          }
        }

        .flex-y-left {
          font-weight: var(--pdl-font-weights-bold);
          .other {
            font-size: 14px;
            height: 14px;
            line-height: 14px;
            font-weight: var(--pdl-font-weights-bold);

            img {
              margin-right: 5px;
            }
          }
          > span {
            margin-bottom: 16px;

            img {
              height: 14px;
              width: auto;
            }
          }
        }
      }
    }
  }
}
</style>
