<template>
  <div class="container">
    <!-- listening动画组件 -->
    <div
      v-if="isListening"
      :class="isListening ? 'main-animation' : ''"
      class="main"
    ></div>
    <!-- 思考动画组件 转圈圈-->
    <div v-if="isThinking" class="think">
      <div class="brainstorm-container">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
      <!-- <div class="brainstorm-container brainstorm-mini">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div> -->
    </div>
    <!-- 语音输入动画组件  -->
    <div v-if="isReplying" style="height: 0">
      <div class="wave-container replay-container">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </div>
    <!-- 
    <div class="wave-container" v-if="isReplying">
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
    </div> -->
    <div class="balls"></div>
    <span class="tips">{{ tips }}{{ symbol }}</span>
  </div>
</template>
<script>
import { getWsAddr } from "../../config/request.js"
export default {
  name: "",
  data() {
    return {
      isListening: false, // 听
      isThinking: false, // 想
      isReplying: false, // 说
      isConnected: false,
      tips: "Connecting",
      symbol: "",
      connectFreq: 1,
      connectInterval: null,
      chatSocket: null,
      WS_ADDR: getWsAddr(),
      userToken: null,
      recorder: null,
      audioPlayer: null,
      retryInterval: null,
    }
  },
  created() {
    this.isListening = true
    let listenNum = 4
    this.tips = "Listening"
    this.symbol = " "
    const listen = setInterval(() => {
      listenNum--
      if (listenNum < 0) {
        this.tips = "Thinking"
        this.symbol = " "
        this.isListening = false
        this.isThinking = true
        this.isReplying = false
      }
      if (listenNum < -4) {
        this.tips = "Speaking"
        this.symbol = " "
        this.isListening = false
        this.isThinking = false
        this.isReplying = true
      }
      if (listenNum < -8) {
        this.tips = "Listening"
        this.symbol = " "
        this.isListening = true
        this.isThinking = false
        this.isReplying = false
        listenNum = 3
      }
    }, 1000)
  },
  methods: {},
  mounted() {
    let that = this
    if (!this.isConnected) {
      this.connectInterval = setInterval(function () {
        if (that.connectFreq > 3) {
          that.connectFreq = 0
        }
        let symbol = " .".repeat(that.connectFreq)
        that.symbol = symbol
        that.connectFreq += 1
      }, 500)
    }
  },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .container {
    .think {
      margin-top: 190px !important;
    }
    .brainstorm-container {
      width: 50% !important;
      height: 200px !important;
      padding-top: 5%;

      .circle {
        width: 5rem !important;
        height: 5rem !important;
      }
    }

    .wave-container {
      //   margin-top: 8rem !important;
    }

    .brainstorm-mini {
      width: 50px !important;
      height: 50px !important;
      margin-left: 25% !important;
      margin-top: -15% !important;
    }
  }
}

.container {
  // position: absolute;
  // left: 50%;
  // top: 46%;
  // transform: translate(-50%, -50%);
  .think {
    margin-top: 240px;
  }
  .main {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: white;
    margin: 0 auto;
    margin-top: 6rem;
  }

  .main-animation {
    animation: main-listen 2s infinite alternate;
  }

  // listening动画
  @keyframes main-listen {
    0% {
      transform: scale(1);
      filter: brightness(1);
    }

    50% {
      transform: scale(0.9);
      filter: brightness(0.8);
    }

    100% {
      transform: scale(1);
      filter: brightness(1);
    }
  }

  // 思考动画
  @keyframes pushRotate {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    25% {
      transform: translateY(0) rotate(90deg);
    }

    50% {
      transform: translateY(0) rotate(180deg);
    }

    75% {
      transform: translateY(0) rotate(270deg);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .brainstorm-container {
    width: 100px;
    height: 100px;
    position: relative;
    animation: pushRotate 4s linear infinite;
    margin: 0 auto;

    .circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
    }

    .circle:nth-child(1) {
      top: 15px;
      left: 12px;
    }

    .circle:nth-child(2) {
      top: 24px;
      left: 30px;
    }

    .circle:nth-child(3) {
      top: 48px;
      left: 36px;
    }

    .circle:nth-child(4) {
      top: 54px;
      left: 12px;
    }

    .circle:nth-child(5) {
      top: 36px;
      left: 0px;
    }
  }

  .brainstorm-mini {
    width: 50px !important;
    height: 50px !important;
    margin-left: 30%;

    .circle {
      width: 20px !important;
      height: 20px !important;
    }

    .circle:nth-child(1) {
      top: 5px;
      left: 4px;
    }

    .circle:nth-child(2) {
      top: 8px;
      left: 10px;
    }

    .circle:nth-child(3) {
      top: 16px;
      left: 12px;
    }

    .circle:nth-child(4) {
      top: 18px;
      left: 4px;
    }

    .circle:nth-child(5) {
      top: 12px;
      left: 0px;
    }
  }

  .bi-mic {
    color: white;
    font-size: 48px;

    &:hover {
      cursor: pointer;
    }
  }

  .mic-listening {
    font-size: 24px !important;
  }

  .tips {
    color: #00d26a;
    // margin-top: 2rem;
    text-align: center;
    position: absolute;
    bottom: -70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .wave-container {
    display: inline-block;

    .wave {
      height: 2rem;
      width: 1.8rem;
      border-radius: 40%;
      background-color: white;
      display: inline-block;
      vertical-align: text-bottom;
      animation: jump 0.5s infinite alternate;
    }

    // 语音输入跳动动画
    @keyframes jump {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1, 0.7);
      }

      100% {
        transform: scale(1);
      }
    }

    .wave:nth-child(0) {
      animation-delay: 0s;
    }

    .wave:nth-child(1) {
      animation-delay: 0.5s;
    }

    .wave:nth-child(2) {
      animation-delay: 1s;
    }

    .wave:nth-child(3) {
      animation-delay: 1.5s;
    }
  }

  .replay-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .wave {
      height: 3rem;
      width: 2rem;
    }
  }

  .bi-x-circle-fill {
    color: rgb(239, 132, 123);
    font-size: 48px;
    margin-top: 2rem;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
