import { themes } from './model'

const changeStyle = (obj) => {
    let arr = document.getElementsByTagName('header')
    let tabsActive = document.getElementsByClassName('fan-tabs-item')
    for (let key in obj) {
        for (let i = 0; i < arr.length; i++) {
            arr[i].style.setProperty(`--${key}`, obj[key])
        }
        for (let i = 0; i < tabsActive.length; i++) {
            tabsActive[i].style.setProperty(`--${key}`, obj[key])
        }
    }
}

export const setTheme = (themeName) => {

    if (!themeName && localStorage.getItem('primaryColor')) {
        let themeConfig = {
            primaryColor: localStorage.getItem('primaryColor'),
            primaryTextColor: localStorage.getItem('primaryTextColor')
        }
        changeStyle(themeConfig)
    } else {
        localStorage.setItem("theme", themeName);
        const themeConfig = themes[themeName]
        if (themeConfig) {
            localStorage.setItem('primaryColor', themeConfig.primaryColor)
            localStorage.setItem('primaryTextColor', themeConfig.primaryTextColor)
            changeStyle(themeConfig)
        } else {
            let themeConfig = {
                primaryColor: localStorage.getItem('primaryColor'),
                primaryTextColor: localStorage.getItem('primaryTextColor')
            }
            changeStyle(themeConfig)
        }
    }
}