export default {
    namespaced: true,
    state: {
        routesList: [],
        parentRoute: '/'
    },
    mutations: {
        setRoutesList(state, payload) {
            state.routesList = payload
        },
        setParentRoute(state, payload) {
            state.parentRoute = payload
        }
    },
    actions: {
    }
}
