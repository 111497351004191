const GlobalPlugin = {
  install(Vue) {
    Vue.prototype.$verifyToken = function () {
      // 全局token检测以检验用户token是否存在
      if (localStorage.getItem("userData")) {
        const userData = localStorage.getItem("userData")
        return userData
      }
      if (new URLSearchParams(window.location.search).get("userData")) {
        const userData = new URLSearchParams(window.location.search).get("userData") // 查看链接自定义传参里，有没有带有用户token
        if (!userData) {
          // 如果链接自定义传参里都没有保存token，必须重登
          console.log(userData)
          window.location.href = "/"
        } else {
          // 链接自定义传参里有token，就用链接传参的
          return userData // 返回userData值
        }
      } else {
        return false // 如果都没有
      }
    }
  },
}

export default GlobalPlugin
