<template>
    <div class="index">
        <el-container>
      <!-- 侧边栏 -->
      <el-aside width="300px">
        <header class="el-logo flex-x-between-centerHor">
          <span>奇妙人</span>
          <i></i>
        </header>
        <menu class="el-navs">
          <section class="PageWithSidebarNav">
            <div class="flex-x-between-centerHor">
              <div v-for="(item,index) in functions" style="width: 42px;">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="item.icon">
                <path fill-rule="evenodd" clip-rule="evenodd" :d="item.iconPath"></path></svg>
                <span>{{ item.described }}</span>
              </div>
            </div>
          </section>
          <section class="PageWithSidebarNav"></section>
        </menu>
      </el-aside>
      <el-main>
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-main>
    </el-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "index",
    components: {},
    data() {
      return {
      }
    },
    watch: {
  
    },
    methods: {
  
    },
    mounted() {
  
    },
  };
  </script>
  